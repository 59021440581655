import React, { useState, useEffect } from 'react';
import styles from './Menu.module.css';
import * as AuthAPI from '@src/lib/api/auth';
import { useAuth } from '@src/providers/Auth';

const Menu = ({ open }) => {
  const { userInfo } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    setIsAuthenticated(!!userInfo);
  }, [userInfo]);

  const [tablist, setTablist] = useState(['info', 'requestcase', 'bookmark', 'highlight', 'history']);

  const [tabNames, setTabNames] = useState({
    info: '회원 정보',
    requestcase: '내가 요청한 판례',
    bookmark: '북마크',
    highlight: '하이라이트',
    history: '히스토리',
  });

  //check payment
  useEffect(() => {
    const getPayment = async () => {
      if (isAuthenticated) {
        try {
          const res = await AuthAPI.checkPayment();
          const paymenthistory = 'paymenthistory';
          if (res.data.data.payment) {
            setTabNames({ ...tabNames, [paymenthistory]: '결제 내역' });
            setTablist((tablist) => [...tablist, 'paymenthistory']);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    getPayment();
  }, [userInfo]);

  const handleClick = (e) => {
    e.preventDefault();
    window.location.replace(`/user/profile/${e.target.name}`);
  };

  const RenderIsAuth = () => {
    return (
      <div className={styles.personal}>
        {tablist.map((e, idx) => {
          return (
            <form key={idx} className={styles.buttonWarp} name={e} onClick={handleClick}>
              <button name={e} className={styles.menuButton}>
                {tabNames[e]}
              </button>
            </form>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.menuWarp}>
      <div
        className={styles.menu}
        style={{
          display: open ? 'block' : 'none',
        }}
      >
        {isAuthenticated ? (
          <RenderIsAuth />
        ) : (
          <div className={styles.user}>
            <a href='/user/login'>
              <div className={styles.login}>로그인</div>
            </a>
            <a href='/user/signup'>
              <div className={styles.signup}>회원가입</div>
            </a>
          </div>
        )}
        <div className={styles.standard}>
          {
            <a className={styles.aiAtag} href='https://ai.legalengine.co.kr/crimesearch'>
              <div className={styles.ailink}> {`무죄판결 검색`}</div>
              <div className={styles.newTag}>{`new`}</div>
            </a>
          }
          {/*           <a href='/requestcase'>
            <div className={styles.link}>판례 요청</div>
          </a> */}
          <a href='/posts'>
            <div className={styles.link}>포스트</div>
          </a>
          <a href='/translator'>
            <div className={styles.link}>법률 번역</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Menu;
