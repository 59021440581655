import Head from 'next/head';
//import Cookie from 'js-cookie';
//import { useEffect } from 'react';

const DefaultHead = (props) => {
  //useEffect(() => {
  //  window.dataLayer = window.dataLayer || [];
  //  function gtag() {
  //    dataLayer.push(arguments);
  //  }
  //  if (Cookie.get('user_id')) {
  //    gtag('set', { user_id: Cookie.get('user_id') });
  //  }
  //  gtag('js', new Date());
  //  gtag('config', 'UA-148180056-1');
  //}, []);

  return (
    <Head>
      {/*<script async src='https://www.googletagmanager.com/gtag/js?id=UA-148180056-1' />*/}
      {/* <script
        dangerouslySetInnerHTML={{
          __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'UA-148180056-1');
              `,
        }}
      /> */}
      <link rel='search' href='/open-search.xml' title='리걸엔진' type='application/opensearchdescription+xml'></link>
    </Head>
  );
};

export default DefaultHead;
