import React from 'react';
import styles from './Hamburger.module.css';

const Hamburger = ({ open, setOpen }) => {
  return (
    <div className={styles.moblie}>
      <div className={styles.hamburger} open={open} onClick={() => setOpen(!open)}>
        <div
          className={styles.burger}
          style={{
            transform: open ? 'rotate(45deg)' : 'rotate(0)',
            top: open ? '3px' : '0',
          }}
        />
        <div
          className={styles.burger}
          style={{
            opacity: open ? '0' : '1',
            top: open ? '0' : '0',
          }}
        />
        <div
          className={styles.burger}
          style={{
            transform: open ? 'rotate(-45deg)' : 'rotate(0)',
            top: open ? '3px' : '0',
          }}
        />
      </div>
    </div>
  );
};

export default Hamburger;
