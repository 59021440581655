import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import styles from './Footer.module.css';
import WindowDimension from '@src/components/Base/WindowDimension';

const Footer = () => {
  const { width } = WindowDimension();

  return (
    <footer>
      <div className={styles.footerArea}>
        <div className={styles.footer} style={{ display: width > 993 ? 'block' : 'none' }}>
          <Row>
            <Col md='3'></Col>
            <Col md='6'>
              <div className={styles.footerContents}>
                <div className={styles.footerLeft}>
                  <div className={styles.corporateName}>주식회사 까리용 © 2019 Carillon Inc., All rights reserved.</div>
                  <div className={styles.corporateInfo}>
                    <span>대표: 오경원 &ensp; | &ensp; 주소: 서울시 강남구 테헤란로 503, 1207호</span>
                  </div>
                  <div className={styles.corporateNumber}>사업자 등록번호: 257-81-01787</div>
                  <div className={styles.corporateNumber}>통신판매업 신고번호: 제 2021-서울강남-03354호</div>
                  <div className={styles.corporateContact}>이메일: contact@legalengine.co.kr</div>
                </div>
                <div className={styles.footercenter} />
                <div className={styles.footerRight}>
                  <div className={styles.searchArea}>
                    <a
                      href='/search?q=무죄&p=1&level1_case=전문%20판례&level0=판례&bub_nm_category=서울고등법원'
                      className={styles.search}
                      target='_blank'
                    >
                      판례검색
                    </a>
                  </div>
                  <div className={styles.privacyPolicy}>
                    <a href='/termsofservice' className={styles.corporateTermsOfService} target='_blank'>
                      이용약관
                    </a>
                  </div>
                  <div className={styles.termsOfService}>
                    <a href='/privacypolicy' className={styles.corporatePrivacyPolicy} target='_blank'>
                      개인정보 처리방침
                    </a>
                  </div>
                  <div className={styles.update}>
                    <a href='/update' className={styles.updatePage} target='_blank'>
                      업데이트
                    </a>
                  </div>
                  <div className={styles.update}>
                    <a href='/aboutus' className={styles.updatePage} target='_blank'>
                      회사소개
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col md='3'></Col>
          </Row>
        </div>
        <div className={styles.footer} style={{ display: width > 993 ? 'none' : 'block' }}>
          <Row className={styles.mobileRow}>
            <Col xs='6'>
              <a href='/termsofservice' className={styles.corporateTermsOfService} target='_blank'>
                이용약관
              </a>
            </Col>
            <Col xs='6'>
              <a href='/privacypolicy' className={styles.corporatePrivacyPolicy} target='_blank'>
                개인정보 처리방침
              </a>
            </Col>
          </Row>
          <Row className={styles.mobileRow}>
            <Col xs='6'>
              <a href='/update' className={styles.updatePage} target='_blank'>
                업데이트
              </a>
            </Col>
            <Col xs='6'>
              <a href='/aboutus' className={styles.aboutUs} target='_blank'>
                회사소개
              </a>
            </Col>
            <Col xs='6'>
              <a
                href='/search?q=무죄&p=1&level1_case=전문%20판례&level0=판례&bub_nm_category=서울고등법원'
                className={styles.search}
                target='_blank'
              >
                판례검색
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <div className={styles.mobileCoporateFooter}>
                <div className={styles.mobileCoporateInfo}>주식회사 까리용 &ensp; | &ensp; 대표 : 오경원 </div>
                <div>주소: 서울시 강남구 테헤란로 503, 1207호</div>
                <div>사업자등록번호: 257-81-01787</div>
                <div>통신판매업 신고번호: 제 2021-서울강남-03354호</div>
                <div>이메일: contact@legalengine.co.kr</div>
              </div>
              <div className={styles.mobileCoporateCopyright}>© 2019 Carillon Inc., All rights reserved.</div>
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
